import React from 'react'
import TableComponet from '../../components/TableComponent'

const Iqacmembers = () => {

    const members=[ 
        { name: 'Dr.Satya Upadhyaya', designation: 'Principal'},
        { name: 'Prof.Prem Kumar Ghosh', designation: 'Co-ordinator'},
        { name: 'Dr.Suparna Bhattacharya', designation: 'Member.'},
        { name: 'Prof.Amalesh Patra', designation: 'Member'},
        { name: 'Dr.Lekhraj Balmiki', designation: 'Member'},
        { name: 'Dr.Sharmila Saha', designation: 'Member'},
        { name: 'Dr.Debashis Biswas', designation:'Member'},
        { name: 'Prof.Nupur Roy', designation: 'Member'},
        { name: 'Dr.Imtiaz Ahmed', designation: 'Member'}

    ]
  return (
    <div className='section-shadow'>
         <h2>Members of IQAC </h2><br></br>
        <i>The IQAC Sub-committee was formed on WEF 26.9.2024 vide G.B. Resolution</i><br></br>
        <TableComponet tabledata={members}/>
    </div>
  )
}

export default Iqacmembers