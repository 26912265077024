import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import AboutComponent from './AboutComponent'
import Iqacmembers from './Iqacmembers';
import Bestpractise from './Bestpractise';
import Ssr from './Ssr';
import ComplianceReport from './ComplianceReport';
import MinutesOfMeeting from './MinutesOfMeeting';
import AccreditionCertificate from './AccreditionCertificate';
import AisheCertificate from './AisheCertificate';
import AqarReport from './AqarReport';
import GreenAudit from './GreenAudit';
import ActionTaken from './ActionTaken';
import AnnualReport from './AnnualReport';
import StakeholderFeedback from '../../Pages/StakeholderFeedback';
import AcademicAudit from '../../Pages/AcademicAudit_temp';


 

const AdmissionSidebar = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [queryParameters] = useSearchParams()
  const contentRef = useRef(null);

const findIndexByTitle = (inputTitle, array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].title === inputTitle) {
      return i; // Return the index of the matching object
    }
  }
  return 0; // Return -1 if no match is found
};



  const handleTabClick = (index) => {
    setActiveTab(index);
    setIsMenuOpen(false);
  };


  const tabContents = [
    {
      title: 'About Us IQAC',
      content: (
        <div>
          <AboutComponent />
        </div>
      )
    },
    {
      title: 'Members',
      content: (
        <><Iqacmembers /></>
      )
    },
    {
      title: 'Minutes of Meeting',
      content: (
        <div>
        <br></br>
          <MinutesOfMeeting />
        </div>
      )
    },
    {
      title: 'Annual Report',
      content: (
        <div>
        <br></br>
          <AnnualReport />
        </div>
      )
    },
    {
      title: 'SSR',
      content: (
        <div>
          <br></br>
          <Ssr />
        </div>
      )
    },
    {
      title: 'Best Practices',
      content: (
        <div>
          <br></br>
          <Bestpractise />
        </div>
      )
    },
    {
      title: 'Action Taken',
      content: (
        <div>
        <br></br>
          <ActionTaken/>

        </div>
      )
    },
    {
      title: 'Compliance Report',
      content: (
        <div>
        <br></br>
          <ComplianceReport/>
        </div>
      )
    },
    {
      title: 'Accreditation Certificate',
      content: (
        <div>
          <br></br>
          <AccreditionCertificate/>
     
        </div>
      )
    },
    {
      title: 'AISHE',
      content: (
        <div>
          <br></br>
          <AisheCertificate/>
        </div>
      )
    },
    {
      title: 'AQAR',
      content: (
        <div>
          <br></br>
      
          <AqarReport/>
        </div>
      )
    },
    {
      title: 'Green Audit',
      content: (
        <div>
   
          <GreenAudit/>
        </div>
      )
    },
    {
      title: 'Academic & Administrative Audit ',
      content: (
        <div>
   
          <AcademicAudit/>
        </div>
      )
    },

    {
      title: 'Feedback Report Analysis',
      content: (
        <div>
   
          <StakeholderFeedback/>
        </div>
      )
    },
  ];

  useEffect(() => {
    if(queryParameters.get("title") !== undefined){
      setActiveTab(findIndexByTitle(queryParameters.get("title"),tabContents))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParameters])
  

 
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const updateScreenSize = () => {
    setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return (
    <>

      <div className={`vertical-tabs ${isMobile ? 'mobile-view' : ''}`}>
        {isMobile && (
          <>
            <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            {isMenuOpen && (
              <div className="menu-dropdown">
                {tabContents.map((tab, index) => (
                  <div
                    key={index}
                    className={`tab ${index === activeTab ? 'mobactive' : ''}`}
                    onClick={() => handleTabClick(index)}
                  >
                    {tab.title}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        {!isMobile && (
          <div className="tab-list colorful-pattern">
            {tabContents.map((tab, index) => (
              <div
                key={index}
                className={`tab ${index === activeTab ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                {tab.title}
              </div>
            ))}
          </div>
        )}
        <div className="tab-content" ref={contentRef}>
          {tabContents[activeTab].content}
        </div>
      </div>
    </>
  );
};

export default AdmissionSidebar;